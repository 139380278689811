// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var bottomMenu = "home-module--bottomMenu--27f0c";
export var carousel = "home-module--carousel--8fd77";
export var carouselLogoContainer = "home-module--carouselLogoContainer--30679";
export var ctaContainer = "home-module--ctaContainer--da536";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var hero = "home-module--hero--2fc3f";
export var heroSection = "home-module--heroSection--bc0a5";
export var imageShadow = "home-module--imageShadow--b1624";
export var page = "home-module--page--f0950";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var subTitle = "home-module--subTitle--906ea";
export var subTitleTotal = "home-module--subTitleTotal--4a06a";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";
export var title = "home-module--title--94ea7";