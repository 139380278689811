import * as React from 'react';
import * as styles from "../../styles/components/home/infinite-carousel.module.scss"

type Props = {
  animationSpeed: number,
  children: any,
}

export const InfiniteCarousel = ({animationSpeed, children = []}: Props) => {
    const trackStyle = {
        animationDuration: `${animationSpeed}s`,
    }


  return (
    <div className={styles.marquee}>
      <div
        className={styles.marqueeContent}
       style={trackStyle}
      >
        {children}
        {children}
        {children}
      </div>
    </div>
  )
}
