import * as styles from "../../styles/components/header.module.scss";
import logo from "../../assets/images/Logo.svg";
import * as React from "react";
import ContentContainer, {ContentContainerType} from "../ContentContainer";
import HeaderLogo from "../header/HeaderLogo";
import HeaderActions, {HeaderActionsColor} from "../header/HeaderActions";

export type HomeHeaderProps = {
    ctaLink: string,
    ctaLabel: string,
    setMenuOpen: (flag: boolean) => void,
    menuLabel: string
}

export default (props: HomeHeaderProps) => {
    return (
        <ContentContainer type={ContentContainerType.homeHeader}>
            <header className={`${styles.headerContainer} ${styles.homeHeaderContainer}`}>
                <HeaderLogo logo={logo} />
                <HeaderActions
                    color={HeaderActionsColor.white}
                    ctaLabel={props.ctaLabel}
                    ctaLink={props.ctaLink}
                    menuLabel={props.menuLabel}
                    setMenuOpen={props.setMenuOpen}
                />
            </header>
        </ContentContainer>
    );
}
