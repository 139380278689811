import React, {useEffect} from "react";
import anime from 'animejs/lib/anime.es.js';
import {IconLogoStep1, IconLogoStep2, IconLogoStep3, IconLogoStep4, IconLogoStep5} from "./svg";
import * as styles from "../styles/components/animeted-logo.module.scss"

const STEP_SPEED: number = 3000;

const AnimatedLogo = () => {
  useEffect(() => {
    anime({
      easing: 'easeOutExpo',
      duration: STEP_SPEED ,
      targets: '.step',
      delay: (el: any, i: number) => {
        return i * STEP_SPEED
      },
      loop: true,
      opacity: [0, 1, 0],
      scale: [0, 1, 0],
      rotate: [90, 180, 270],
    });

  }, [])

  return (
    <div className={styles.container}>
      <div className={`${styles.step__0} step`}/>
      <IconLogoStep1 isWhite={true} className={`${styles.step__1} step`}/>
      <IconLogoStep2 isWhite={true} className={`${styles.step__2} step`}/>
      <IconLogoStep3 isWhite={true} className={`${styles.step__3} step`}/>
      <IconLogoStep4 isWhite={true} className={`${styles.step__4} step`}/>
      <IconLogoStep5 isWhite={true} className={`${styles.step__5} step`}/>
    </div>
  )
}

export default AnimatedLogo
