import * as React from 'react'
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react'

const ANIMATION_TIMER = 1000;
const ANIMATION_BUFFER = 200;
const MINIMAL_DELTA_Y_DIFFERENCE = 1;
// const DEFAULT_CONTAINER_HEIGHT = "calc(var(--vh) * 100)";
// const DEFAULT_CONTAINER_WIDTH = "100vw";

export type PageScrollerProps = {
    children: React.ReactNode,
    isMobileView: boolean
};

export default (props: PageScrollerProps) => {
    const scrollContainer = useRef<HTMLDivElement>(null);
    const pageContainer = useRef<HTMLDivElement>(null);
    const [componentIndex, setComponentIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const childrenLength = React.Children.count(props.children);

    useEffect(() => {
        scrollPage(0);
    }, [props.isMobileView]);

    const canScroll = useCallback((nextComponentIndex) => {
        return !isScrolling && nextComponentIndex < childrenLength && nextComponentIndex >= 0;
    }, [isScrolling, childrenLength]);


    const scrollWindowDown = useCallback(() => {
        if (canScroll(componentIndex + 1)) {
            setIsScrolling(true);
            scrollPage(componentIndex + 1);
            setTimeout(() => {
                setComponentIndex(prevState => prevState + 1);
                setIsScrolling(false);
            }, ANIMATION_TIMER + ANIMATION_BUFFER);
        }

    }, [componentIndex, isScrolling]);

    const scrollWindowUp = useCallback(() => {
        if (canScroll(componentIndex - 1)) {
            setIsScrolling(true);
            scrollPage(componentIndex - 1);
            setTimeout(() => {
                setComponentIndex(prevState => prevState - 1);
                setIsScrolling(false);
            }, ANIMATION_TIMER + ANIMATION_BUFFER);
        }

    }, [componentIndex, isScrolling]);

    const scrollPage = useCallback((nextComponentIndex) => {
        if(pageContainer.current) {
            pageContainer.current.style.transform = `translate3d(0, ${nextComponentIndex * -100}%, 0)`;
        }

    }, []);

    const wheelScroll = useCallback(
        event => {
            const pagesScrollerParent = event.target.closest('#page-scroller');

            if (Math.abs(event.deltaY) > MINIMAL_DELTA_Y_DIFFERENCE && pagesScrollerParent && !props.isMobileView) {
                if (event.deltaY > 0) {
                    scrollWindowDown();
                } else {
                    scrollWindowUp();
                }
            }
        },
        [scrollWindowDown, scrollWindowUp, props.isMobileView],
    );

    return (
        <div
            ref={scrollContainer}
            id="page-scroller"
            style={{
                overflow: "hidden",
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
            }}
        >
            <div
                ref={pageContainer}
                onWheel={wheelScroll}
                style={{
                    height: "100%",
                    width: "100%",
                    transition: `transform ${ANIMATION_TIMER}ms ease-in-out`,
                    outline: "none",
                }}
                tabIndex={0}
            >
                {React.Children.map(props.children, (child, index) => {
                    const item = child as ReactElement;
                    return (
                        <div key={index} style={{ height: "100%", width: "100%" }}>
                            {React.cloneElement(item, { ...item.props })}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};
