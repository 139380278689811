// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var container = "animeted-logo-module--container--f3077";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var primaryColor = "#fff";
export var secondaryColor = "#000";
export var step__0 = "animeted-logo-module--step__0--3eea0";
export var step__1 = "animeted-logo-module--step__1--2cfe7";
export var step__2 = "animeted-logo-module--step__2--09378";
export var step__3 = "animeted-logo-module--step__3--d5e28";
export var step__4 = "animeted-logo-module--step__4--5b49d";
export var step__5 = "animeted-logo-module--step__5--1ec3a";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";