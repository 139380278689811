import * as React from 'react';
import {useEffect, useState} from 'react';
import {autoPlay} from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import * as styles from "../../styles/components/home/home-image-carousel.module.scss"
import {ImageModel} from "../../store/models/image";
import ProgressiveImage from '../common/ProgressiveImage';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


type HomeImageCarouselProps = {
    images: ImageModel[]
}

export const HomeImageCarousel = (props: HomeImageCarouselProps) => {
    const [activeStep, setActiveStep] = useState(0);
    let maxSteps = 1;

    useEffect(() => {
        if (props.images) {
            maxSteps = props.images.length;
        }
    }, [])

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div className={styles.container}>
            <div className={styles.background}></div>
            <AutoPlaySwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                className={styles.carousel}
                containerStyle={{width: '100%', height: '100%'}}
                slideStyle={{overflow: 'hidden'}}
                interval={5000}
                springConfig={{
                    duration: '0.6s',
                    easeFunction: 'ease',
                    delay: '0s'
                }}
            >
                {props.images.map((step: ImageModel, index: any) => (
                    <ProgressiveImage
                        image={step}
                        className={styles.image}
                        disableAutoHeight
                        style={{objectFit: 'cover'}}
                    />
                ))}
            </AutoPlaySwipeableViews>
        </div>
    )
}
